<template>
    <div class="studyRecord">
        <div v-if="recordList.length > 0" style="width:94%" class="marginAuto">
            <course-list :courseList="recordList" :min="true" :showType="1" :studyRe="true" :showNumber="4"></course-list>
        </div>
        <temp-data v-else></temp-data>
    </div>
</template>
<script>
import courseList from '@/components/courseList'
import tempData from '@/components/tempData'
import { my_look_log } from '@/utils/Api/userList'
export default {
    name:'studyRecord',
    components:{courseList, tempData},
    data(){
        return{
            recordList:[]
        }
    },
    created(){
        this.initialBefore()
    },
    methods:{
        async initialBefore(){
            let res = await my_look_log()
            console.log(res)
            if(res){
                this.recordList = res.res
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.studyRecord{

}
</style>